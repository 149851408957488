import { CapacitorGoogleNotifications } from '@kim5257/capacitor-google-notifications';

import firebaseConfig from '@/config/firebase.json';

const options = {
  vapidKey: 'BPJBIrn6rsfNdZULZX3FSUFO6iSSLD1otJ084k6RwOnZSionsMHtfWnw4hxe-k5gJwTgcFf5OgGA0d1umf4Utaw',
};

let registration = null;

async function initialize() {
  await CapacitorGoogleNotifications.initialize({
    initialize: false,
    initializeSw: true,
  }, firebaseConfig);

  /*
  if ('serviceWorker' in navigator) {
    try {
      registration = await navigator.serviceWorker.register('/service-worker.js', {
        type: 'module',
      });

      await navigator.serviceWorker.ready;

      console.log('Service Worker registered with scope:', registration.scope);
    } catch (error) {
      console.log('Service Worker registration failed:', error);
    }
  }
   */

  await requestPermissions();

  await CapacitorGoogleNotifications.addListener('registration', (token) => {
    // eslint-disable-next-line no-undef
    console.log('registration - Token:', JSON.stringify(token));
  });

  await CapacitorGoogleNotifications.addListener('registrationError', (error) => {
    // eslint-disable-next-line no-undef
    console.log('registrationError - Error:', JSON.stringify(error));
  });

  await CapacitorGoogleNotifications.addListener('pushNotificationReceived', (message) => {
    // eslint-disable-next-line no-undef
    console.log('pushNotificationReceived - message:', JSON.stringify(message));
  });

  await CapacitorGoogleNotifications.addListener('pushNotificationActionPerformed', (action) => {
    // eslint-disable-next-line no-undef
    console.log('pushNotificationActionPerformed - action:', JSON.stringify(action));
  });


}

async function requestPermissions() {
  const requestStatus = await CapacitorGoogleNotifications.requestPermissions()
  if ( requestStatus.receive === 'granted') {
    await CapacitorGoogleNotifications.register(options);
  }
}

(async () => {
  await initialize();
})();

export default {
  requestPermissions,
}
