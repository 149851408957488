/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '../store'
import router, { initializeNavigationGuard } from '../router'
import { createEnhancedRouter } from '@kim5257/enhanced-router';
import VueSocketIO from '@kim5257/vue-socket.io-pinia/src';
import SocketIO from 'socket.io-client';
import * as SocketIOParser from '@kim5257/socket.io-parser-bigint';
import { useAppStore } from '@/store/app';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { Capacitor } from '@capacitor/core';
import '@/capacitor/app';

const apiHost = (Capacitor.getPlatform() === 'web')
  ? '/'
  : 'https://cointoss.ai';

export function registerPlugins (app) {
  app
    .use(vuetify)
    .use(pinia)
    .use(router)
    .use(createEnhancedRouter({ router, debug: true }))
    .use(VueVirtualScroller);

  initializeNavigationGuard();

  const vueSocketIO = new VueSocketIO({
    connection: SocketIO(apiHost, {
      transports: ['websocket'],
      parser: SocketIOParser,
    }),
    pinia: {
      store: useAppStore(),
      actionPrefix: 'socket.',
    },
    debug: true,
  });

  app.use(vueSocketIO);
}
