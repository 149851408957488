import { App } from '@capacitor/app';
import { Toast } from '@capacitor/toast';
import router from '@/router';

let pressedBack = 0;

App.addListener('backButton', async () => {
  const canGoBack = window.goBack();

  console.log('canGoBack:', canGoBack);

  pressedBack += 1;

  if (!canGoBack && pressedBack > 1) {
    await App.exitApp();
  } else if (!canGoBack) {
    await Toast.show({
      text: '이전 버튼을 다시 누르면 종료됩니다.',
      duration: 'short',
      position: 'bottom',
    });

    setTimeout(() => {
      pressedBack = 0;
    }, 1500)
  }
}).then(() => {});

router.afterEach(async (to, from, error) => {
  console.log('app-afterEach:', to, error, pressedBack);

  if (error != null) {
    if (pressedBack === 1) {
      await Toast.show({
        text: '이전 버튼을 다시 누르면 종료됩니다.',
        duration: 'short',
        position: 'bottom',
      });
    } else if (pressedBack > 1) {
      await App.exitApp();
    }
  } else {
    pressedBack = 0;
  }
})
