// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { useAppStore } from '@/store/app';

const routes = [
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '/market',
        name: 'Market',
        component: () => import('@/views/main/MarketView.vue'),
        meta: { title: '종목정보' },
        children: [
          {
            path: '/market/foreign_stocks',
            name: 'ForeignStocks',
            component: () => import('@/views/main/market/ForeignStocks.vue'),
            meta: { title: '전체종목' },
          },
          {
            path: '/market/favorite_stocks',
            name: 'FavoriteStocks',
            component: () => import('@/views/main/market/FavoriteStocks.vue'),
            meta: { title: '관심종목' },
          }
        ]
      },
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/main/FinanceView.vue'),
        meta: { title: '홈' },
        redirect: '/finance/spot',
        children: [
          {
            path: '/finance/spot',
            name: 'SpotView',
            component: () => import('@/views/main/finance/SpotView.vue'),
            meta: { title: '현물' },
          },
          {
            path: '/finance/future',
            name: 'FutureView',
            component: () => import('@/views/main/finance/FutureView.vue'),
            meta: { title: '선물' },
          },
          {
            path: '/finance/trend',
            name: 'TrendView',
            component: () => import('@/views/main/finance/TrendView.vue'),
            meta: { title: '트렌드' },
          },
          {
            path: '/finance/profitassume',
            name: 'ProfitAssumeView',
            component: () => import('@/views/main/finance/ProfitAssumeView.vue'),
            meta: { title: '수익률 계산기' },
          },
        ]
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('@/views/main/NewsView.vue'),
        meta: { title: '뉴스' },
      },
      {
        path: '/video',
        name: 'Video',
        component: () => import('@/views/main/VideoView.vue'),
        meta: { title: '영상' },
      },
      {
        path: '/event',
        name: 'Event',
        component: () => import('@/views/main/EventView.vue'),
        meta: { title: '이벤트' },
      },
      {
        path: '/more',
        name: 'More',
        component: () => import('@/views/main/MoreView.vue'),
        meta: { title: '더 보기' },
      },
    ],
    meta: { needAuth: false },
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/LoginView.vue'),
    meta: { title: '로그인', needNonAuth: true },
  },
  {
    path: '/verification',
    name: 'PhoneVerificationView',
    component: () => import('@/views/PhoneVerificationView.vue'),
    meta: { title: '번호인증', needAuth: true },
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: () => import('@/views/ProfileView.vue'),
    meta: { title: '내설정', needAuth: true },
  },
  {
    path: '/notice',
    name: 'NoticeView',
    component: () => import('@/views/NoticeView.vue'),
    meta: { title: '공지사항', needAuth: false, needNonAuth: false },
  },
  {
    path: '/notice/:noticeNo',
    name: 'NoticeContentView',
    component: () => import('@/views/NoticeContentView.vue'),
    meta: { title: '공지사항', needAuth: false, needNonAuth: false },
    props: (route) => ({
      noticeNo: BigInt(route.params.noticeNo),
    }),
  },
  {
    path: '/delete',
    name: 'DeleteAccount',
    component: () => import('@/views/DeleteAccount.vue'),
    meta: { title: '회원 탈퇴', needAuth: true },
  },
  {
    path: '/kakao_login',
    name: 'KakaoLogin',
    component: () => import('@/views/KakaoLogin.vue'),
  },
  {
    path: '/naver_login',
    name: 'NaverLogin',
    component: () => import('@/views/KakaoLogin.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export function initializeNavigationGuard() {
  router.beforeEach(async (to, from, next) => {
    console.log('beforeEach:', to, from);
    next();
  });
}

window.goBack = () => {
  const ret = router.goBack();

  const appStore = useAppStore();

  console.log('goBack:', appStore.initPath, history.state.back, ret);

  return ('/login' !== history.state.back) && ret;
}

export default router
