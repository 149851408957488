<template>
  <v-dialog
    v-bind="$attrs"
    v-model="showFlag"
    :persistent="persistent">
    <v-form
      style="height: 100%;"
      @submit.prevent="">
      <v-card>
        <slot name="title">
          <v-toolbar
            class="dlg-base-toolbar"
            :color="'#ffffff'">
            <v-toolbar-title>
              {{ title }}
            </v-toolbar-title>
            <v-spacer>
            </v-spacer>
            <v-btn
              v-if="showClose"
              @click="$router.goBack()"
              icon="mdi-close">
            </v-btn>
          </v-toolbar>
        </slot>
        <v-card-text
          class="pa-0">
          <slot>
          </slot>
        </v-card-text>
        <v-card-actions
          v-if="$slots.actions != null">
          <slot name="actions">
          </slot>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'DlgBase',
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: 'Title',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  watch: {
    showFlag: {
      immediate: true,
      handler(value) {
        this.$emit('update:show', this.showFlag);

        if (value) {
          this.$emit('reset', this.showFlag);
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dlg-base-toolbar {
  border-bottom: 1px solid #eeeeee;
}
</style>
