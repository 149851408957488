// Utilities
import { defineStore } from 'pinia'
import { Capacitor } from '@capacitor/core';
import capAuthentication from '@/capacitor/authentication';

export const useAppStore = defineStore('app', {
  state: () => ({
    appLoading: true,           // 앱 초기 접속 때는 로딩 표시
    initPath: null,             // 앱 인증 처리 완료 후 접속 초기 주소
    firebaseInitialized: false, // Firebase 인증 절차 완료됨
    firebaseAuthorized: false,  // Firebase 인증 완료됨
    connected: false,           // API 서버에 연결됨
    authorized: false,          // 전체 인증 완료됨
    userInfo: null,             // 인증 후 사용자 정보
    connectionError: false,     // API 서버 접속 에러 여부
    initialized: false,         // 동작 초기화 완료됨

    // Service Worker 관련
    needRefresh: false,         // 서비스 워커 업데이트로 갱신 필요
    updateSW: null,             // 서비스 워커 업데이트 함수

    // API 서버 주소
    // Capacitor 기반의 네이티브 앱의 경우 앱 주소가 로컬이므로 서버 주소 명시
    clientHost: (Capacitor.isNativePlatform())
      ? 'https://cointoss.ai' : ''
  }),
  actions: {
    'socket.connect': async function (io) {
      this.connected = true;
      this.connectionError = false;
    },
    'socket.disconnect': async function () {
      this.resetAuthorize();
    },
    'socket.connect_error': async function (socket, error) {
      this.connectionError = false;

      switch (error.message) {
        case 'websocket error': {
          // API 서버 접속 에러
          // 접속 실패 페이지 표시
          this.connectionError = true;
          break;
        }
        default: {
          this.handleExError(error);
          break;
        }
      }
    },
    resetAuthorize() {
      this.initPath = null;
      this.firebaseInitialized = false;
      this.firebaseAuthorized = false;
      this.connected = false;
      this.authorized = false;
      this.userInfo = null;
      this.connectionError = false;
      this.initialized = false;
    },
    handleExError(error) {
      console.error('API Connection Error:', error);
      this.connectionError = true;
    },
    async signOut(io) {
      return new Promise((resolve) => {
        io.emit('users.deauthentication', {}, async (resp) => {
          if (resp.result !== 'success') {
            console.error('signOut Error:', resp);
          }

          await capAuthentication.signOut();
          this.firebaseAuthorized = false;
          this.authorized = false;

          resolve();
        });
      });
    },
  },
})
