import { GoogleAuthentication } from '@kim5257/capacitor-google-authentication';
import { useAppStore } from '@/store/app';

import firebaseConfig from '@/config/firebase.json';

(async () => {
  await GoogleAuthentication.addListener('google.auth.state.update', async (data) => {
    console.log('google.auth.state.update:', data);

    const appStore = useAppStore();

    if (data.idToken !== '') {
      const userResp = await GoogleAuthentication.getCurrentUser();
      if (userResp.result === 'success') {
        appStore.userInfo = userResp.user;
        appStore.firebaseAuthorized = true;
      }
    }

    appStore.firebaseInitialized = true;
  });

  await GoogleAuthentication.initialize({
    ...firebaseConfig,
    googleClientId: '487593176107-q5rteqr315v7p5mp9o5j1ar0eh8c8237.apps.googleusercontent.com',
  });

  console.log('GoogleAuthentication:', GoogleAuthentication);
})();

async function refreshUser() {
  const tokenResp = await GoogleAuthentication.getIdToken({ forceRefresh: true });

  if (tokenResp.result === 'success') {
    const userResp = await GoogleAuthentication.getCurrentUser();
    if (userResp.result === 'success') {
      const appStore = useAppStore();

      appStore.userInfo = userResp.user;
      appStore.firebaseAuthorized = true;
    }
  }
}

export default {
  verifyPhoneNumber: GoogleAuthentication.verifyPhoneNumber,
  confirmPhoneNumber: GoogleAuthentication.confirmPhoneNumber,
  createUserWithEmailAndPassword: GoogleAuthentication.createUserWithEmailAndPassword,
  signInWithEmailAndPassword: GoogleAuthentication.signInWithEmailAndPassword,
  signInWithGoogle: GoogleAuthentication.signInWithGoogle,
  signInWithCustomToken: GoogleAuthentication.signInWithCustomToken,
  getIdToken: GoogleAuthentication.getIdToken,
  echo: GoogleAuthentication.echo,
  addListener: GoogleAuthentication.addListener,
  getCurrentUser: GoogleAuthentication.getCurrentUser,
  linkWithPhone: GoogleAuthentication.linkWithPhone,
  confirmLinkPhoneNumber: GoogleAuthentication.confirmLinkPhoneNumber,
  updateProfile: GoogleAuthentication.updateProfile,
  updatePhoneNumber: GoogleAuthentication.updatePhoneNumber,
  confirmUpdatePhoneNumber: GoogleAuthentication.confirmUpdatePhoneNumber,
  signOut: GoogleAuthentication.signOut,
  refreshUser,
};
