<template>
  <dlg-base
    title="업데이트"
    :max-width="360"
    :persistent="true">
    <div
      class="pa-4">
      새 버전이 업데이트되었습니다.<br>
    </div>
    <template v-slot:actions>
      <v-btn
        color="success"
        @click="onReload"
        :loading="loading">
        새로고침
      </v-btn>
      <v-btn
        @click="$emit('update:show', false)">
        닫기
      </v-btn>
    </template>
  </dlg-base>
</template>

<script>
import DlgBase from '@/components/DlgBase';
import { mapState } from 'pinia';
import { useAppStore } from '@/store/app';

export default {
  name: 'DlgUpdateApp',
  components: {
    DlgBase,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(useAppStore, {
      updateSW: 'updateSW',
    }),
  },
  methods: {
    onReload() {
      this.loading = true;
      this.updateSW();
    }
  },
};
</script>

<style scoped lang="scss">

</style>
