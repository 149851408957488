import { defineStore } from 'pinia';

const defaultTimeout = 5000;

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    show: false,
    body: {
      color: null,
      icon: null,
      title: null,
      text: null,
    },
    timeout: defaultTimeout,
    timeoutId: null,
  }),
  actions: {
    showNotification(body, timeout=null) {
      this.body = {
        color: 'warning',
        icon: null,
        title: null,
        ...body,
      };
      this.show = true;

      this.timeout = (timeout != null) ? timeout : defaultTimeout;

      this.timeoutId = setTimeout(() => {
        this.show = false;
        this.timeoutId = null;
      }, this.timeout)
    },
    hideNotification() {
      this.show = false;

      if (this.timeoutId != null) {
        clearTimeout(this.timeoutId);
      }
    },
  },
});

export function showNotification(body, timeout=null) {
  const notificationStore = useNotificationStore();
  notificationStore.showNotification(body, timeout);
}
