<template>
  <v-app>
    <v-main>
      <template
        v-if="!appLoading">
        <router-view />
        <v-snackbar
          location="top"
          :model-value="notificationShow"
          :color="notificationBody.color">
          <div
            class="d-flex flex-row">
            <v-icon
              v-if="notificationBody.icon != null"
              class="mr-4"
              :icon="notificationBody.icon">
            </v-icon>
            <div
              class="d-flex flex-column justify-start">
              <div
                class="v-alert-title">
                {{ notificationBody.title }}
              </div>
              <div>
                {{ notificationBody.text }}
              </div>
            </div>
          </div>
        </v-snackbar>
        <v-overlay
          v-if="connectionError"
          class="align-center justify-center"
          :model-value="true"
          :persistent="true"
          location-strategy="connected"
          scroll-strategy="reposition">
          <v-card
            class="pa-4 justify-center">
            <div
              class="mb-4">
              <v-icon
                class="mr-2">
                mdi-lan-disconnect
              </v-icon>
              현재 서버와 접속이 원할하지 않습니다.
            </div>
            <v-progress-linear
              color="primary"
              :indeterminate="true">
            </v-progress-linear>
          </v-card>
        </v-overlay>
        <dlg-update-app
          v-model:show="needRefresh">
        </dlg-update-app>
      </template>
      <template
        v-else>
        <div
          class="fill-height d-flex flex-column justify-center align-center pa-4">
          <div
            class="d-flex mb-8">
            <v-img
              :width="96"
              :rounded="true"
              alt="logo"
              :src="appLogo">
            </v-img>
          </div>
          <v-progress-linear
            style="width: 280px;"
            color="secondary"
            :indeterminate="true">
          </v-progress-linear>
        </div>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import capAuthentication from '@/capacitor/authentication';
import { useAppStore } from '@/store/app.js';
import { useNotificationStore } from '@/store/notification.js';
import DlgUpdateApp from '@/components/DlgUpdateApp';

import appLogo from '@/assets/coin_toss_logo.png';

export default {
  name: 'App',
  components: {
    DlgUpdateApp,
  },
  data: () => ({
    appLogo,
    event: new EventTarget(),
  }),
  computed: {
    ...mapState(useAppStore, {
      initPath: 'initPath',
      firebaseInitialized: 'firebaseInitialized',
      firebaseAuthorized: 'firebaseAuthorized',
      connected: 'connected',
      userInfo: 'userInfo',
      connectionError: 'connectionError',
    }),
    ...mapWritableState(useAppStore, {
      appLoading: 'appLoading',
      authorized: 'authorized',
      initialized: 'initialized',
      needRefresh: 'needRefresh',
    }),
    ...mapState(useNotificationStore, {
      notificationShow: 'show',
      notificationBody: 'body',
      notificationTimeout: 'timeout'
    }),
  },
  watch: {
    connected: {
      immediate: true,
      handler() {
        this.checkAuthorize();
      },
    },
    firebaseInitialized: {
      immediate: true,
      handler() {
        this.checkAuthorize();
      },
    },
    firebaseAuthorized: {
      immediate: true,
      handler() {
        this.checkAuthorize();
      },
    },
    initialized: {
      immediate: true,
      handler(value) {
        console.log('initialized:', value);
      },
    },
  },
  mounted() {
    this.event.addEventListener('refreshToken', this.onRefreshToken);
  },
  unmounted() {
    this.event.removeEventListener('refreshToken', this.onRefreshToken);
  },
  methods: {
    async onRefreshToken() {
      // Firebase 토큰 갱신 후 다시 인증 절차 요청
      await capAuthentication.refreshUser();
      await this.checkAuthorize();
    },
    async checkAuthorize() {
      // API 서버 연결되어있으며,
      // Firebase 인증 과정에서 인증이 되었다면,
      // API 서버에 인증 처리 요청
      if (this.connected && this.firebaseInitialized) {
        if (this.firebaseAuthorized) {
          const resp = await this.authenticationUser()
          if (resp.result === 'success') {
            this.authorized = true;
          }
        }

        await this.checkRouter();

        this.initialized = true;
        this.appLoading = false;
      }
    },
    async authenticationUser() {
      return new Promise((resolve) => {
        this.$socket.emit('users.authentication', {
          accessToken: this.userInfo.accessToken,
        }, async (resp) => {
          console.log('authenticationUser:', resp, this.userInfo);

          // 만약 토큰의 유효기간이 만료라면,
          // 토큰 재 발급 요청 후 다시 시도하도록 함.
          if (resp.result !== 'success') {
            switch (resp.code) {
              case 'auth/id-token-expired':
                this.event.dispatchEvent(new CustomEvent('refreshToken'));
                break;
            }
          }

          resolve(resp);
        });
      });
    },
    async checkRouter() {
      const route = this.$route;
      if ((route.meta.needAuth && !this.authorized)
        || (route.meta.needNonAuth && this.authorized)) {
        console.log('Goto root page by checkRouter');
        await this.$router.push('/');
      }
    },
    async checkNotification() {
      capNotification
    },
  },
}
</script>
